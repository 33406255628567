import React from 'react'
import Title from 'src/components/shared/Title'
import Wrapper from 'src/components/shared/Wrapper'
import { css } from 'styled-components'

const Contact: React.FCX = (props) => {
  return (
    <div className={`${props.className} page-wrapper`}>
      <Wrapper
        css={css`
          max-width: 680px;
        `}
      >
        <Title type={['color_main']} text="CONTACT" sub="お問い合わせ" />
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeFeM8QpaNztlhZrislAoeQl_X1fu1fvYkwxixFltjhQfPbkg/viewform?embedded=true"
          width="640"
          height="826"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          読み込んでいます…
        </iframe>
      </Wrapper>
    </div>
  )
}

export default Contact
